<template>
	<section class="col-12">
		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.insurance') }}</b>
				</h3>
			</div>
		</div>
		<Field>
			<template slot="title">{{ $t('companyInsurance.tillagslon') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.supplementarySalary.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.supplementarySalary.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.supplementarySalary.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="insurance.supplementarySalary.value" :disabled="true" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('global.startDate') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.startDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.startDate.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.startDate.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputDate v-model="employeeInsurance.startDate.value" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.expirationDate') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.expirationDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.expirationDate.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.expirationDate.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown v-model="insurance.expirationDate.value" :options="['65', '67', 'Folkepension']" :disabled="true" :placeholder="'Udløbsdato'" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.waitingPeriode') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.waitingPeriode.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.waitingPeriode.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.waitingPeriode.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputDropdown
					v-model="insurance.waitingPeriode.value"
					:options="['Straks', '3 måneder', '6 måneder', '9 måneder']"
					:disabled="true"
					:placeholder="'Periode'"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.drawingWindow') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.drawingWindow.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.drawingWindow.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.drawingWindow.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown v-model="insurance.drawingWindow.value" :options="['3 måneder', '6 måneder']" :disabled="true" :placeholder="'Periode'" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.tabarbejdsevnepct') }}</template>
			<template slot="taxCode">
				<TaxCodes
					ref="workAbilityTaxCode"
					v-model="workAbilityTaxCode"
					:showTax="true"
					:tax="[
						{ code: '1', taxFree: false },
						{ code: '7', taxFree: true },
					]"
					:disabled="disabled"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.workAbility.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.workAbility.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.workAbility.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="employeeInsurance.workAbility.isSalaryScale" :disabled="disabled" :text="'Lønskala'" />
				<InputNumber
					v-model="insurance.workAbility.value"
					:min="insurance.workAbility.coverageSpanMin"
					:max="insurance.workAbility.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.workAbility.value"
					:min="insurance.workAbility.coverageSpanMin"
					:max="insurance.workAbility.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="employeeInsurance.workAbility.isSalaryScale || disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.workAbility.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.workAbility.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>

				<InputDropdownValuta class="mt-1" v-model="insurance.workAbility.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.tabarbejdsevneudb') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.workAbilityPayment.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.workAbilityPayment.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.workAbilityPayment.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="insurance.workAbilityPayment.value"
					:options="[
						{ value: 'standard', text: $t('global.paymentEmployer') },
						{ value: 'expanded', text: $t('global.paymentEmployee') },
					]"
					:disabled="true"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.pensionbidragpct') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.pensionContribution.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.pensionContribution.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.pensionContribution.comment" :disabled="disabled" />
			</template>
			<template slot="taxCode">
				<TaxCodes
					ref="pensionContributionTaxCode"
					v-model="pensionContributionTaxCode"
					:showTax="true"
					:tax="[
						{ code: '1', taxFree: false },
						{ code: '7', taxFree: true },
					]"
					:disabled="disabled"
				/>
			</template>
			<template slot="comp">
				<InputCheck
					v-model="insurance.pensionContribution.sameAsMandatoryContribution"
					:disabled="standardCoverageDisabled"
					:text="$t('global.sameAsMandatoryContribution')"
					@change.native="$emit('pensionContributionChanged')"
				/>

				<InputNumber
					v-model="insurance.pensionContribution.value"
					:min="insurance.pensionContribution.coverageSpanMin"
					:max="insurance.pensionContribution.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.pensionContribution.value"
					:min="insurance.pensionContribution.coverageSpanMin"
					:max="insurance.pensionContribution.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="insurance.pensionContribution.sameAsMandatoryContribution || disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.pensionContribution.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.pensionContribution.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.pensionContribution.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.invalidesum') }}
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.invalideSum.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.invalideSum.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.invalideSum.comment" :disabled="disabled" />
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					ref="invalideSumTaxCode"
					v-model="invalideSumTaxCode"
					:disabled="disabled"
					:text="$t('global.taxCodes.taxCode5') + ' ' + $t('global.taxFree')"
					:code="5"
				/>
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.invalideSum.value"
					:min="insurance.invalideSum.coverageSpanMin"
					:max="insurance.invalideSum.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.invalideSum.value"
					:min="insurance.invalideSum.coverageSpanMin"
					:max="insurance.invalideSum.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.invalideSum.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.invalideSum.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.invalideSum.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.dodfaldssum') }}
			</template>

			<template slot="taxCode">
				<TaxCodes
					ref="deathSumTaxCode"
					:disabled="disabled"
					v-model="deathSumTaxCode"
					:showTax="true"
					:tax="[
						{ code: '2', taxFree: false },
						{ code: '5', taxFree: true },
					]"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.deathSum.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.deathSum.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.deathSum.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.deathSum.value"
					:min="insurance.deathSum.coverageSpanMin"
					:max="insurance.deathSum.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.deathSum.value"
					:min="insurance.deathSum.coverageSpanMin"
					:max="insurance.deathSum.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.deathSum.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.deathSum.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.deathSum.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.bornepension') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					ref="childPensionTaxCode"
					v-model="childPensionTaxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:disabled="disabled"
					:code="5"
				/>
				<InputDropdown
					v-model="insurance.criticalDiseaseChild.ageGroup"
					:options="['Under 18 år', 'Under 21 år', 'Under 24 år']"
					:disabled="standardCoverageDisabled"
					:placeholder="'Aldersgruppe'"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.childPension.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.childPension.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.childPension.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.childPension.value"
					:min="insurance.childPension.coverageSpanMin"
					:max="insurance.childPension.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.childPension.value"
					:min="insurance.childPension.coverageSpanMin"
					:max="insurance.childPension.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.childPension.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.childPension.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.childPension.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.kritisksygdom') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					ref="criticalDiseaseTaxCode"
					:disabled="disabled"
					v-model="criticalDiseaseTaxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:code="5"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.criticalDisease.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.criticalDisease.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.criticalDisease.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.criticalDisease.value"
					:min="insurance.criticalDisease.coverageSpanMin"
					:max="insurance.criticalDisease.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.criticalDisease.value"
					:min="insurance.criticalDisease.coverageSpanMin"
					:max="insurance.criticalDisease.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDisease.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDisease.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.criticalDisease.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyInsurance.kritisksygdomborn') }}
			</template>

			<template slot="taxCode">
				<TaxCodeCheck
					ref="criticalDiseaseChildTaxCode"
					v-model="criticalDiseaseChildTaxCode"
					:text="$t('global.taxCodes.taxCode5') + ' - ' + $t('global.taxFree')"
					:disabled="disabled"
					:code="5"
				/>
				<InputDropdown
					v-model="insurance.criticalDiseaseChild.ageGroup"
					:options="['Under 18 år', 'Under 21 år', 'Under 24 år']"
					:disabled="true"
					:placeholder="'Aldersgruppe'"
				/>
			</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.criticalDiseaseChild.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.criticalDiseaseChild.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.criticalDiseaseChild.comment" :disabled="disabled" />
			</template>

			<template slot="comp">
				<InputNumber
					v-model="insurance.criticalDiseaseChild.value"
					:min="insurance.criticalDiseaseChild.coverageSpanMin"
					:max="insurance.criticalDiseaseChild.coverageSpanMax"
					:type="$t('global.cvge')"
					:disabled="standardCoverageDisabled"
				/>
				<InputNumber
					v-model="employeeInsurance.criticalDiseaseChild.value"
					:min="insurance.criticalDiseaseChild.coverageSpanMin"
					:max="insurance.criticalDiseaseChild.coverageSpanMax"
					:type="$t('global.chooseCoverage')"
					:disabled="disabled"
				/>
				<div class="row">
					<div class="col-12">
						{{ $t('global.cvgeSpan') }}
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDiseaseChild.coverageSpanMin" :limit="$t('global.min')" :disabled="standardCoverageDisabled" />
					</div>
					<div class="col-auto p-0">
						<p class="line">-</p>
					</div>
					<div class="col">
						<InputMinMax v-model="insurance.criticalDiseaseChild.coverageSpanMax" :limit="$t('global.max')" :disabled="standardCoverageDisabled" />
					</div>
				</div>
				<InputDropdownValuta class="mt-1" v-model="insurance.criticalDiseaseChild.type" :disabled="standardCoverageDisabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">Betaling via faktura eller integreteret pension</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurancePayment.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="standardInsurance.healthInsurancePayment.comment" :disabled="true" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="standardInsurance.healthInsurancePayment.value"
					:options="[
						{ value: 'invoice', text: 'Faktura' },
						{ value: 'pension', text: 'Pension' },
					]"
					:disabled="true"
				/>
			</template>
		</Field>

		<Field v-for="(standardHealthInsurance, index) in insurance.healthInsurances" :key="index">
			<template slot="title">{{ supplierInsuranceTemplate.healthInsurances[index].title }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurances[index].comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="standardInsurance.comment" :disabled="true" />
			</template>
			<template slot="comp">
				<InputCheck v-model="standardInsurance.healthInsurances[index].value" :disabled="true" />
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikring') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurance.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsurance.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.healthInsurance.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="employeeInsurance.healthInsurance.insuranceType"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
				<InputDate
					v-model="employeeInsurance.healthInsurance.date"
					:options="[
						{ value: 'standard', text: $t('global.paymentEmployer') },
						{ value: 'expanded', text: $t('global.paymentEmployee') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikringborn') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsuranceChild.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsuranceChild.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.healthInsuranceChild.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="employeeInsurance.healthInsuranceChild.insuranceType"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">{{ $t('companyInsurance.sundhedsforsikringmedforsikrede') }}</template>
			<template slot="supplierComment" v-if="supplierInsuranceTemplate">
				<Comment v-model="supplierInsuranceTemplate.healthInsurancePartner.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="insurance.healthInsurancePartner.comment" :disabled="true" />
			</template>
			<template slot="employeeComment">
				<EmployeeComment v-model="employeeInsurance.healthInsurancePartner.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputRadio
					v-model="employeeInsurance.healthInsurancePartner.value"
					:options="[
						{ value: true, text: $t('global.yes') },
						{ value: false, text: $t('global.no') },
					]"
					:disabled="disabled"
				/>
				<InputRadio
					v-model="employeeInsurance.healthInsurancePartner.insuranceType"
					v-if="employeeInsurance.healthInsurancePartner.value == true"
					:options="[
						{ value: 'standard', text: $t('global.std') },
						{ value: 'expanded', text: $t('global.expanded') },
					]"
					:disabled="disabled"
				/>
			</template>
		</Field>
	</section>
</template>

<script>
import Field from '@/modules/global/atomComponents/Field'
import Comment from '@/modules/global/atomComponents/InputComment'
import EmployeeComment from '@/modules/global/atomComponents/InputEmployeeComment'
import TaxCodes from '@/modules/global/atomComponents/InputTaxCode.vue'
import TaxCodeCheck from '@/modules/global/atomComponents/InputTaxCodeCheck.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputRadio from '@/modules/global/atomComponents/InputRadio.vue'
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'
import InputMinMax from '@/modules/global/atomComponents/InputMinMax.vue'
import InputDropdownValuta from '@/modules/global/atomComponents/InputDropdownValuta.vue'

export default {
	name: 'EmployeeInsurance',
	components: {
		Field,
		InputNumber,
		InputRadio,
		InputDate,
		TaxCodes,
		TaxCodeCheck,
		Comment,
		InputDropdown,
		InputCheck,
		InputMinMax,
		InputDropdownValuta,
		EmployeeComment,
	},
	props: {
		value: Object,
		standardInsurance: Object,
		insuranceIndex: Number,
		disabled: Boolean,
	},
	data() {
		return {
			standardCoverageDisabled: true,
		}
	},
	methods: {},
	computed: {
		supplierInsuranceTemplate() {
			if (this.insurance?.supplierTemplateId) {
				var suppliers = this.$store.getters['supplier/suppliers']
				var supplierId = this.insurance.supplierId
				var supplier = suppliers.find((supplier) => supplier.id == supplierId)
				var insurance = supplier?.groupTemplates?.find((template) => template.id == this.insurance.supplierTemplateId)?.insurance
				return insurance
			} else {
				return null
			}
		},
		insurance: {
			get() {
				return this.standardInsurance
			},
		},
		employeeInsurance: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		workAbilityTaxCode: {
			get() {
				if (this.employeeInsurance.workAbility.taxCode === '') {
					console.log("this.insurance.workAbility.taxCode", this.insurance.workAbility.taxCode)
					return this.insurance.workAbility.taxCode
				} else {
					return this.employeeInsurance.workAbility.taxCode
				}
			},
			set(value) {
				console.log(value)
				this.employeeInsurance.workAbility.taxCode = value
			},
		},
		pensionContributionTaxCode: {
			get() {
				if (this.employeeInsurance.pensionContribution.taxCode === '') {
					return this.insurance.pensionContribution.taxCode
				} else {
					return this.employeeInsurance.pensionContribution.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.pensionContribution.taxCode = value
			},
		},
		invalideSumTaxCode: {
			get() {
				if (this.employeeInsurance.invalideSum.taxCode === '') {
					return this.insurance.invalideSum.taxCode
				} else {
					return this.employeeInsurance.invalideSum.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.invalideSum.taxCode = value
			},
		},
		deathSumTaxCode: {
			get() {
				if (this.employeeInsurance.deathSum.taxCode === '') {
					return this.insurance.deathSum.taxCode
				} else {
					return this.employeeInsurance.deathSum.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.deathSum.taxCode = value
			},
		},
		childPensionTaxCode: {
			get() {
				if (this.employeeInsurance.childPension.taxCode === '') {
					return this.insurance.childPension.taxCode
				} else {
					return this.employeeInsurance.childPension.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.childPension.taxCode = value
			},
		},
		criticalDiseaseTaxCode: {
			get() {
				if (this.employeeInsurance.criticalDisease.taxCode === '') {
					return this.insurance.criticalDisease.taxCode
				} else {
					return this.employeeInsurance.criticalDisease.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.criticalDisease.taxCode = value
			},
		},
		criticalDiseaseChildTaxCode: {
			get() {
				if (this.employeeInsurance.criticalDiseaseChild.taxCode === '') {
					return this.insurance.criticalDiseaseChild.taxCode
				} else {
					return this.employeeInsurance.criticalDiseaseChild.taxCode
				}
			},
			set(value) {
				this.employeeInsurance.criticalDiseaseChild.taxCode = value
			},
		},



	},
}
</script>

<style></style>
